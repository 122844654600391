<template>
  <div>
    <!-- 广告 -->
    <!-- 代表内容的序号 -->
    <div
      v-if="
        (i + pagingI + 1) % newsListNum == 0 &&
        newsList[(i + pagingI + 1) / newsListNum - 1] &&
        !newsList[(i + pagingI + 1) / newsListNum - 1].isShow
      "
      style="width: 100%"
    >
      <div
        class="advertise"
        @click.stop="
          jumpTo(
            newsList[(i + pagingI + 1) / newsListNum - 1].url,
            newsList[(i + pagingI + 1) / newsListNum - 1].id,
            newsList[(i + pagingI + 1) / newsListNum - 1].type
          )
        "
      >
        <div class="advertise_top">
          <div class="advertise_topDiv">
            <div class="advertise_top_text">
              {{ newsList[(i + pagingI + 1) / newsListNum - 1].title }}
            </div>
            <div class="rightchengDiv">
              <div class="rightchengDivSonDiv">
                <img class="advertiseImg" src="@image/gg.png" />
                <span class="rightchengDivSpan"
                  >{{ newsList[(i + pagingI + 1) / newsListNum - 1].business }}
                </span>
              </div>
              <div>
                <i
                  class="el-icon-close closeImg"
                  @click.stop="closeAd((i + pagingI + 1) / newsListNum - 1)"
                ></i>
              </div>
            </div>
          </div>
          <div class="chengDiv">
            <img
              class="advertiseimage"
              :src="newsList[(i + pagingI + 1) / newsListNum - 1].images"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 广告 -->

    <el-dialog
      @click.native.stop
      title="提示"
      :visible.sync="dialogVisibleXin"
      width="460px"
    >
      <span>是否确认不再浏览此广告?</span>
      <span
        slot="footer"
        class="dialog-footer"
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding-top: 30px;
        "
      >
        <el-button @click.stop="dialogVisibleXin = false">取 消</el-button>
        <el-button type="primary" @click.stop="advertiseSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "advertise",
  props: {
    // 广告列表
    newsList: {
      type: Array,
      default() {
        return [];
      },
    },
    // 插入的广告
    newsListNum: {
      type: Number,
      default: 0,
    },
    // 下标
    i: {
      type: Number,
      default: 0,
    },

    page: {
      type: Number,
      default: 1,
    },

    pageSize: {
      type: Number,
      default: 10,
    },
  },

  data() {
    return {
      // 删除广告对话框
      dialogVisibleXin: false,
      // 删除的广告
      delIndex: 0,
    };
  },

  computed: {
    pagingI() {
      return this.pageSize * (this.page - 1);
    },
  },

  methods: {
    // 广告的跳转
    jumpTo(url, id, type) {
      if (type == 4) {
        return;
      }
      if (type != 3) {
        this.$router.push({
          path: "/index-asdvertising",
          query: {
            is_adv: id,
          },
        });
      } else {
        window.open(url, "_blank");
      }
    },

    // 删除广告
    closeAd(index) {
      this.dialogVisibleXin = true;
      this.delIndex = index;
    },

    // 关闭广告对话框确认
    advertiseSure() {
      this.dialogVisibleXin = false;
      this.$emit("advertiseSure", this.delIndex);
    },
  },
};
</script>


<style lang="scss" scope>
.advertise {
  padding: 0 0 6px;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .advertise_top {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;

    .advertise_top_text {
      width: 100%;
      font-size: 18px;
      color: #333333;
      margin-bottom: 10px;
      margin-right: 20px;
    }

    .advertiseimage {
      // width: 210px;
      // height: 160px;
      // border-radius: 10px;
      // border: 2px solid #f2f2f2;
      width: 200px;
      height: 150px;
      border-radius: 10px;
    }
  }

  .advertise_bot {
    margin: 20px 0 6px;
    display: flex;
    justify-content: space-between;
  }

  .closeImg {
    width: 20px;
    height: 20px;
  }

  .chengDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
  }

  .chengDivDaughterDiv {
    margin-left: 300px;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .advertise_topDiv {
    width: 70%;
    // height:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 150px;
  }

  .rightchengDiv {
    display: flex;
    flex-direction: row;
    color: #8f8f8f;
    font-size: 16px;
    // margin-left: 20px;
    line-height: 1.8;
    // height: 100px;

    justify-content: space-between;
    align-items: space-between;

    .rightchengDivSpan {
      display: flex;
      flex-direction: row;
      margin-top: auto;
      margin: 0;

      // >.time {
      // 	margin-left: 20px;
      // }
    }

    .rightchengDivSonDiv {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .advertiseImg {
      height: 20px;
      margin-right: 20px;
    }
  }
}
</style>
