<template>
  <div>
    <div v-for="(item, i) in discoveryList" :key="item.id">
      <!-- 医学视频 -->
      <div
        class="group-one"
        v-if="item.video_uid"
        @click="
          $router.push({
            path:
              '/index-syllabus-videoLectureDetail?video_uid=' + item.video_uid,
          })
        "
      >
        <div class="group-one-box">
          <img class="banner" :src="item.avatar" />
          <div class="rectangular">
            <img src="@image/bofang.png" />
          </div>
        </div>
        <div class="group-one-right">
          <div class="subTitle">
            {{ item.video_name }}
          </div>
        </div>
      </div>
      <!-- 新闻 -->
      <div class="group-one-right2" v-if="item.new_type" @click="goView(item)">
        <span
          v-html="item.content"
          class="subTitle widwd"
          style="margin-top: 20px; white-space: pre-line"
        ></span>
        <div v-if="isAttention">
          <div class="data" v-if="creation">
            <span>{{ item.praise_num }}赞</span>
            <span>·</span>
            <span>{{ item.comment_num }}评论</span>
            <span>·</span>
            <span>{{ item.browse_num | pageviews }}浏览</span>
            <span>·</span>
            <span>{{ item.create_time | formaTime }}</span>
          </div>
        </div>
      </div>
      <!-- 发布说说 -->
      <div
        class="group-one"
        v-if="item.type == 1 && item.doctor"
        @click="goView(item)"
      >
        <div v-if="item.images.length" class="group-one-box2">
          <img class="banner2" :src="item.images[0]" />
          <div class="rectangular2">
            <span v-if="item.images">{{ item.images.length }}图</span>
          </div>
        </div>
        <div class="group-one-right">
          <div class="group-one-top" v-if="item.doctor && creation">
            <img
              class="avatar"
              :src="
                item.doctor.photo
                  ? item.doctor.photo
                  : require('@image/default.png')
              "
              @click.stop="goPersonal(item)"
            />
            <div class="group-one-top-right">
              <div class="one">
                <span class="title">{{ item.doctor.name }}</span>
                <span class="position">{{ item.doctor.title_name }}</span>
                <template
                  v-if="isAttention && item.doctor.user_id != userInfo.id"
                >
                  <span
                    v-if="item.attention"
                    @click.stop="postDiscoverAttention(item)"
                    class="button2"
                    >已关注</span
                  >
                  <span
                    @click.stop="postDiscoverAttention(item)"
                    v-else
                    class="button"
                    >+关注</span
                  >
                </template>
              </div>
              <span class="address"
                >{{ item.doctor.company }}
                {{ item.doctor.department_name }}</span
              >
            </div>
          </div>
          <div
            v-if="item.images.length > 0"
            class="subTitle"
            style="white-space: pre-line; height: 44px"
            :class="{ line: item.images.length }"
            v-html="item.content"
          ></div>
          <div
            v-else
            class="subTitle"
            style="white-space: pre-line; max-height: 150px; min-height: 44px"
            v-html="item.content"
          ></div>
          <div v-if="isAttention" class="flex-div">
            <!-- <div class="data" style="padding-bottom: 10px" v-if="creation">
              <span>{{ item.praise_num }}赞</span>
              <span>·</span>
              <span>{{ item.comment_num }}评论</span>
              <span>·</span>
              <span>{{ item.browse_num | pageviews }}浏览</span>
              <span>·</span>
              <span>{{ item.create_time | formaTime }}</span>
            </div> -->
            <div>
              <div
                class="data"
                style="padding-bottom: 10px"
                v-if="item.status == 1"
              >
                <span class="creationTitle">发布说说</span>
                <span>正在审核中</span>
              </div>
              <div
                class="data"
                style="padding-bottom: 10px"
                v-if="item.status == 2"
              >
                <span class="creationTitle">发布说说</span>
                <span>{{ item.praise_num }}赞</span>
                <span>·</span>
                <span>{{ item.comment_num }}评论</span>
                <span>·</span>
                <span>{{ item.browse_num | pageviews }}浏览</span>
                <span>·</span>
                <span>{{ item.create_time | formaTime }}</span>
              </div>
              <div
                class="data"
                style="padding-bottom: 10px"
                v-if="item.status == 3"
                @click.stop="edit(item)"
              >
                <span class="creationTitle">发布说说</span>
                <span class="data-bh">审核未通过，重新编辑</span>
                <img
                  class="data-icon"
                  :src="$http.baseURL + '/static/images/web/edit.png'"
                  alt=""
                />
              </div>
            </div>
            <div v-if="isCreation" class="delete-find">
              <i
                class="iconfont bianji"
                @click.stop="findEdit(item)"
                title="编辑文章"
              ></i>
              <i
                class="iconfont shanchu"
                @click.stop="findDelete(item)"
                title="删除文章"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <!-- 病例分享 -->
      <div
        class="group-one-right2"
        v-if="item.type == 2 && item.doctor"
        @click="goView(item)"
      >
        <div class="group-one-top" v-if="item.doctor && creation">
          <img
            class="avatar"
            :src="
              item.doctor.photo
                ? item.doctor.photo
                : require('@image/default.png')
            "
            @click.stop="goPersonal(item)"
          />
          <div class="group-one-top-right">
            <div class="one">
              <span class="title">{{ item.doctor.name }}</span>
              <span class="position">{{ item.doctor.title_name }}</span>

              <template
                v-if="isAttention && item.doctor.user_id != userInfo.id"
              >
                <span
                  v-if="item.attention"
                  @click.stop="postDiscoverAttention(item)"
                  class="button2"
                  >已关注</span
                >
                <span
                  @click.stop="postDiscoverAttention(item)"
                  v-else
                  class="button"
                  >+关注</span
                >
              </template>
            </div>
            <span class="address"
              >{{ item.doctor.company }} {{ item.doctor.department_name }}</span
            >
          </div>
        </div>
        <span
          class="subTitle"
          style="color: 333; white-space: pre-line"
          v-if="item.title"
          v-html="item.title"
        ></span>
        <!-- <span v-html="item.content" class="subTitle2"></span> -->
        <!-- v-html="xin.formatRichText(item.content)" -->
        <span
          v-html="item.content"
          class="subTitle2"
          style="color: #333 !important; white-space: pre-line"
        ></span>
        
        <div v-if="isAttention" class="flex-div">
          <!-- <div class="data" v-if="creation">
            <span>{{ item.praise_num }}赞</span>
            <span>·</span>
            <span>{{ item.comment_num }}评论</span>
            <span>·</span>
            <span>{{ item.browse_num | pageviews }}浏览</span>
            <span>·</span>
            <span>{{ item.create_time | formaTime }}</span>
          </div> -->
          <div>
            <div class="data" v-if="item.status == 1">
              <span class="creationTitle">病例分享</span>
              <span>正在审核中</span>
            </div>
            <div class="data" v-if="item.status == 2">
              <span class="creationTitle">病例分享</span>
              <span>{{ item.praise_num }}赞</span>
              <span>·</span>
              <span>{{ item.comment_num }}评论</span>
              <span>·</span>
              <span>{{ item.browse_num | pageviews }}浏览</span>
              <span>·</span>
              <span>{{ item.create_time | formaTime }}</span>
            </div>
            <div class="data" v-if="item.status == 3" @click.stop="edit(item)">
              <span class="creationTitle" v-if="!creation">病例分享</span>
              <span class="data-bh">审核未通过，重新编辑</span>
              <img
                class="data-icon"
                :src="$http.baseURL + '/static/images/web/edit.png'"
                alt=""
              />
            </div>
          </div>
          <div v-if="isCreation" class="delete-find">
            <i
              class="iconfont bianji"
              @click.stop="findEdit(item)"
              title="编辑文章"
            ></i>
            <i
              class="iconfont shanchu"
              @click.stop="findDelete(item)"
              title="删除文章"
            ></i>
          </div>
        </div>
      </div>

      <!-- 学术视频 -->
      <div
        class="group-one"
        v-if="item.type == 3 && item.doctor"
        @click="goView(item)"
      >
        <div class="group-one-box">
          <img class="banner" :src="item.video_img" />
          <div class="rectangular">
            <img src="@image/bofang.png" />
          </div>
        </div>
        <div class="group-one-right">
          <div class="group-one-top" v-if="item.doctor && creation">
            <img
              class="avatar"
              :src="
                item.doctor.photo
                  ? item.doctor.photo
                  : require('@image/default.png')
              "
              @click.stop="goPersonal(item)"
            />
            <div class="group-one-top-right">
              <div class="one">
                <span class="title">{{ item.doctor.name }}</span>
                <span class="position">{{ item.doctor.title_name }}</span>
                <template
                  v-if="isAttention && item.doctor.user_id != userInfo.id"
                >
                  <span
                    v-if="item.attention"
                    @click.stop="postDiscoverAttention(item)"
                    class="button2"
                    >已关注</span
                  >
                  <span
                    @click.stop="postDiscoverAttention(item)"
                    v-else
                    class="button"
                    >+关注</span
                  >
                </template>
              </div>
              <span class="address"
                >{{ item.doctor.company }}
                {{ item.doctor.department_name }}</span
              >
            </div>
          </div>
          <div v-html="item.title" class="subTitle" style="height: 44px"></div>

          <div
            style="
              overflow: hidden;
              height: 32px;
              line-height: 32px;
              width: 100%;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
              white-space: pre;
            "
          >
            {{ item.content }}
          </div>

          <div v-if="isAttention" class="flex-div">
            <!-- <div class="data" style="padding-bottom: 10px" v-if="creation">
              <span>{{ item.praise_num }}赞</span>
              <span>·</span>
              <span>{{ item.comment_num }}评论</span>
              <span>·</span>
              <span>{{ item.browse_num | pageviews }} 浏览</span>
              <span>·</span>
              <span>{{ item.create_time | formaTime }}</span>
            </div> -->
            <div>
              <div
                class="data"
                style="padding-bottom: 10px"
                v-if="item.status == 1"
              >
                <span class="creationTitle">学术视频</span>
                <span>正在审核中</span>
              </div>
              <div
                class="data"
                style="padding-bottom: 10px"
                v-if="item.status == 2"
              >
                <span class="creationTitle">学术视频</span>
                <span>{{ item.praise_num }}赞</span>
                <span>·</span>
                <span>{{ item.comment_num }}评论</span>
                <span>·</span>
                <span>{{ item.browse_num | pageviews }} 浏览</span>
                <span>·</span>
                <span>{{ item.create_time | formaTime }}</span>
              </div>
              <div
                class="data"
                style="padding-bottom: 10px"
                v-if="item.status == 3"
                @click.stop="edit"
              >
                <span class="creationTitle">学术视频</span>
                <span class="data-bh">审核未通过，重新编辑</span>
                <img
                  class="data-icon"
                  :src="$http.baseURL + '/static/images/web/edit.png'"
                  alt=""
                />
              </div>
            </div>
            <div v-if="isCreation" class="delete-find">
              <i
                class="iconfont bianji"
                @click.stop="findEdit(item)"
                title="编辑文章"
              ></i>
              <i
                class="iconfont shanchu"
                @click.stop="findDelete(item)"
                title="删除文章"
              ></i>
            </div>
          </div>
        </div>
      </div>

      <!-- 指南解析 -->
      <div
        class="group-one-right2"
        v-if="item.type == 4 && item.doctor"
        @click="goView(item)"
      >
        <div class="group-one-top" v-if="item.doctor && creation">
          <img
            class="avatar"
            :src="
              item.doctor.photo
                ? item.doctor.photo
                : require('@image/default.png')
            "
            @click.stop="goPersonal(item)"
          />
          <div class="group-one-top-right">
            <div class="one">
              <span class="title">{{ item.doctor.name }}</span>
              <span class="position">{{ item.doctor.title_name }}</span>
              <template
                v-if="isAttention && item.doctor.user_id != userInfo.id"
              >
                <span
                  v-if="item.attention"
                  @click.stop="postDiscoverAttention(item)"
                  class="button2"
                  >已关注</span
                >
                <span
                  @click.stop="postDiscoverAttention(item)"
                  v-else
                  class="button"
                  >+关注</span
                >
              </template>
            </div>
            <span class="address"
              >{{ item.doctor.company }} {{ item.doctor.department_name }}</span
            >
          </div>
        </div>
        <span
          class="subTitle"
          v-html="item.title"
          style="white-space: pre-line"
        ></span>
        <span
          class="subTitle2"
          v-html="item.content"
          style="white-space: pre-line"
        >
        </span>
        <div class="group">
          <span>疾病名称：</span>
          <span>{{ item.disease }}</span>
        </div>
        <div class="group2">
          <span>搜索词：</span>
          <span>{{ item.search }}</span>
        </div>

        <div v-if="isAttention" class="flex-div">
          <!-- <div class="data" v-if="creation">
            <span>{{ item.praise_num }}赞</span>
            <span>·</span>
            <span>{{ item.comment_num }}评论</span>
            <span>·</span>
            <span>{{ item.browse_num | pageviews }}浏览</span>
            <span>·</span>
            <span>{{ item.create_time | formaTime }}</span>
          </div> -->
          <div>
            <div class="data" v-if="item.status == 1">
              <span class="creationTitle">指南解析</span>
              <span>正在审核中</span>
            </div>
            <div class="data" v-if="item.status == 2">
              <span class="creationTitle">指南解析</span>
              <span>{{ item.praise_num }}赞</span>
              <span>·</span>
              <span>{{ item.comment_num }}评论</span>
              <span>·</span>
              <span>{{ item.browse_num | pageviews }}浏览</span>
              <span>·</span>
              <span>{{ item.create_time | formaTime }}</span>
            </div>
            <div class="data" v-if="item.status == 3" @click.stop="edit">
              <span class="creationTitle">指南解析</span>
              <span class="data-bh">审核未通过，重新编辑</span>
              <img
                class="data-icon"
                :src="$http.baseURL + '/static/images/web/edit.png'"
                alt=""
              />
            </div>
          </div>
          <div v-if="isCreation" class="delete-find">
            <i
              class="iconfont bianji"
              @click.stop="findEdit(item)"
              title="编辑文章"
            ></i>
            <i
              class="iconfont shanchu"
              @click.stop="findDelete(item)"
              title="删除文章"
            ></i>
          </div>
        </div>
      </div>

      <!-- 问答 -->
      <div class="group-two" v-if="item.type == 5" @click="goView(item)">
        <span class="title">{{ item.title }}</span>
        <div class="bottom">
          <div class="banner3" v-if="item.images.length">
            <img :src="item.images[0]" />
            <div class="banner3-icon">{{ item.images.length }}图</div>
          </div>
          <div class="group-two-div">
            <span
              class="subTitle"
              v-html="item.content"
              style="white-space: pre-line"
            ></span>
            <div v-if="isAttention" class="flex-div">
              <div class="group-two-bottom" v-if="creation">
                <img
                  class="avatar"
                  :src="
                    item.doctor.photo
                      ? item.doctor.photo
                      : require('@image/default.png')
                  "
                  @click.stop="goPersonal(item)"
                />
                <span>{{ item.doctor.name }}</span>
                <span>·</span>
                <span>{{ item.doctor.title_name }}</span>
                <span>·</span>
                <span>{{ item.browse_num | pageviews }}浏览</span>
                <span>·</span>
                <span>{{ item.create_time | formaTime }}</span>
                <span class="button">去回答</span>
              </div>
              <div v-else>
                <div class="group-two-bottom" v-if="item.status == 1">
                  <span class="creationTitle">发布问答</span>
                  <span>正在审核中</span>
                </div>
                <div class="group-two-bottom" v-if="item.status == 2">
                  <span class="creationTitle">发布问答</span>
                  <span>{{ item.browse_num | pageviews }}浏览</span>
                  <span>·</span>
                  <span>{{ item.create_time | formaTime }}</span>
                </div>
                <div
                  class="group-two-bottom"
                  v-if="item.status == 3"
                  @click.stop="edit"
                >
                  <span class="creationTitle">发布问答</span>
                  <span class="data-bh">审核未通过，重新编辑</span>
                  <img
                    class="data-icon"
                    :src="$http.baseURL + '/static/images/web/edit.png'"
                    alt=""
                  />
                </div>
              </div>
              <div v-if="isCreation" class="delete-find">
                <i
                  class="iconfont bianji"
                  @click.stop="findEdit(item)"
                  title="编辑文章"
                ></i>
                <i
                  class="iconfont shanchu"
                  @click.stop="findDelete(item)"
                  title="删除文章"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 发布论文 -->
      <div
        class="group-one-right2"
        v-if="item.type == 11 && item.doctor"
        @click="goView(item)"
      >
        <div class="group-one-top" v-if="item.doctor && creation">
          <img
            class="avatar"
            :src="
              item.doctor.photo
                ? item.doctor.photo
                : require('@image/default.png')
            "
            @click.stop="goPersonal(item)"
          />
          <div class="group-one-top-right">
            <div class="one">
              <span class="title">{{ item.doctor.name }}</span>
              <span class="position">{{ item.doctor.title_name }}</span>
              <template
                v-if="isAttention && item.doctor.user_id != userInfo.id"
              >
                <span
                  v-if="item.attention"
                  @click.stop="postDiscoverAttention(item)"
                  class="button2"
                  >已关注</span
                >
                <span
                  @click.stop="postDiscoverAttention(item)"
                  v-else
                  class="button"
                  >+关注</span
                >
              </template>
            </div>
            <span class="address"
              >{{ item.doctor.company }} {{ item.doctor.department_name }}</span
            >
          </div>
        </div>
        <span
          v-html="item.title"
          class="subTitle"
          style="white-space: pre-line"
        ></span>
        <span
          class="subTitle2"
          v-html="item.content"
          style="white-space: pre-line"
        ></span>

        <div v-if="isAttention" class="flex-div">
          <!-- <div class="data" v-if="creation">
            <span>{{ item.praise_num }}赞</span>
            <span>·</span>
            <span>{{ item.comment_num }}评论</span>
            <span>·</span>
            <span>{{ item.browse_num | pageviews }}浏览</span>
            <span>·</span>
            <span>{{ item.create_time | formaTime }}</span>
          </div> -->
          <div>
            <div class="data" v-if="item.status == 1">
              <span class="creationTitle">发布论文</span>
              <span>正在审核中</span>
            </div>
            <div class="data" v-if="item.status == 2">
              <span class="creationTitle">发布论文</span>
              <span>{{ item.praise_num }}赞</span>
              <span>·</span>
              <span>{{ item.comment_num }}评论</span>
              <span>·</span>
              <span>{{ item.browse_num | pageviews }}浏览</span>
              <span>·</span>
              <span>{{ item.create_time | formaTime }}</span>
            </div>
            <div class="data" v-if="item.status == 3" @click.stop="edit">
              <span class="creationTitle">发布论文</span>
              <span class="data-bh">审核未通过，重新编辑</span>
              <img
                class="data-icon"
                :src="$http.baseURL + '/static/images/web/edit.png'"
                alt=""
              />
            </div>
          </div>
          <div v-if="isCreation" class="delete-find">
            <i
              class="iconfont bianji"
              @click.stop="findEdit(item)"
              title="编辑文章"
            ></i>
            <i
              class="iconfont shanchu"
              @click.stop="findDelete(item)"
              title="删除文章"
            ></i>
          </div>
        </div>
      </div>

      <!-- 广告 -->
      <advertise
        v-show="showAdvertise"
        :newsList="newsList"
        :newsListNum="newsListNum"
        :i="i"
        @advertiseSure="advertiseSure"
        :page="page"
        :pageSize="pageSize"
      >
      </advertise>
      <!-- 广告 -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import advertise from "@components/common/advertise";

export default {
  components: {
    advertise,
  },
  data() {
    return {
      islod: true,
      newsList: [],
      newsListNum: 0,
    };
  },
  computed: {
    ...mapState("userManagement", ["userInfo"]),

    showAdvertise() {
      return (
        this.$route.path != "/found-personal" &&
        this.$route.path != "/creation" &&
        this.$route.path != "/index" &&
        !this.recommend
      );
    },
  },
  props: {
    idnex: {
      type: Number,
      default: 0,
    },
    // 是否显示用户的关注按钮 (默认显示)
    isAttention: {
      type: Boolean,
      default: true,
    },
    isCreation: {
      type: Boolean,
      default: false,
    },
    recommend: {
      type: Boolean,
      default: false,
    },
    // i: {
    //   type: Number,
    //   default: 0,
    // },
    discoveryList: {
      type: Array,
      default() {
        return [];
      },
    },

    page: {
      type: Number,
      default: 1,
    },

    pageSize: {
      type: Number,
      default: 10,
    },

    // item: {
    //   type: Object,
    //   default() {
    //     return {
    //       doctor: {},
    //       data: {},
    //     };
    //   },
    // },

    creation: {
      type: Boolean,
      default: true,
    },
  },
  async mounted() {
    await this.adv_listFound();
  },
  async created() {
    await this.adv_listFound();
  },
  methods: {
    // 举报
    // chengReport(report, id, type, userId) {
    //   let token = localStorage.getItem("token") || "";
    //   if (!token) {
    //     return this.$message({
    //       message: "请登录后操作",
    //       type: "error",
    //     });
    //   } else {
    //     this.bus.$emit(report, id, type, userId, token);
    //   }
    // },

    // 广告列表
    async adv_listFound() {
      let res = await this.$findApi.adv_listFound({});
      if (res.code == 200) {
        this.newsList = res.data.list;
        this.newsListNum = res.data.rules.num;
        // this.newsList.push({"isShow":isShow})
        this.newsList.forEach((item) => {
          item.isShow = false;
        });
      } else {
        this.$message.error(res.message);
      }
    },

    // 关闭广告对话框确认
    advertiseSure(index) {
      this.newsList[index].isShow = true;
    },

    async findDelete(item) {
      //删除文章
      this.$msgbox({
        title: "消息",
        message: "您确定要删除改文章吗?",
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: async (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "执行中...";
            let { code, message } = await this.$userApi.postDelDiscover({
              discover_id: item.id,
            });
            if (code == 200) {
              done();
              this.$emit("deleteInd", this.idnex);
              this.$router.go();
              this.xin.message("删除成功！");
            } else {
              this.$message.error(message);
            }
            setTimeout(() => {
              instance.confirmButtonLoading = false;
            }, 300);
          } else {
            done();
          }
        },
      });
    },
    async findEdit(item) {
      //编辑文章
      this.$router.push({
        path:
          "/found-release-index?id=" +
          item.id +
          "&type=" +
          item.type +
          "&from=creation",
      });

      this.findDetail(item.id);
    },
    async findDetail(id) {
      this.xin.isLoadding(true);
      let res = await this.$findApi.findDetail({
        discover_id: id,
      });
      if (res.code == 200) {
        //     let data = res.data || [];
        //     if (data.info && data.info.images) {
        //       data.info.images = JSON.parse(data.info.images);
        //     } else {
        //       data.info.images = [];
        //     }
        //     if (data.recommend.length) {
        //       for (let i in data.recommend) {
        //         data.recommend[i].images
        //           ? (data.recommend[i].images = JSON.parse(
        //               data.recommend[i].images
        //             ))
        //           : (data.recommend[i].images = []);
        //       }
        //     }
        //     this.detail = data;
      } else {
        this.$message.error(res.message);
      }
      this.xin.isLoadding();
    },

    goPersonal(item) {
      if (this.creation) {
        let url = "/found-personal?id=" + item.doctor.user_id;
        this.xin.goUrl(url);
      }
    },
    // 去编辑
    edit(item) {
      let url = "/found-release-index?id=" + item.id + "&type=" + item.type;
      this.xin.goUrl(url);
    },

    // 跳转
    goView(item) {
      if (!this.creation && item.status == 1) {
        this.$message({
          message: "正在审核中",
          type: "error",
        });
      } else if (!this.creation && item.status == 3) {
        this.$message({
          message: "审核未通过，请重新发布或编辑",
          type: "error",
        });
      } else {
        let url;
        if (item.new_type) {
          //新闻
          url = "/index-newsDetail?id=" + item.id;
        } else if (item.type == 3) {
          // 视频
          url = "/found-details-videoDetails?id=" + item.id;
        } else if (item.type == 5) {
          // 问答
          url = "/found-qa?id=" + item.id;
        } else {
          url = "/found-details-details?id=" + item.id;
        }
        this.xin.goUrl(url);
      }
    },
    ...mapActions("userManagement", ["getPersonal"]),
    // 关注
    async postDiscoverAttention(item) {
      let token = localStorage.getItem("token") || "";
      if (!token) {
        return this.$message({
          message: "请登录后操作",
          type: "error",
        });
      }

      // 防止多次发送请求
      // if (this.islod) {
      // this.islod = false;
      let res = await this.$userApi.postDiscoverAttention({
        attention_id: item.user_id,
      });
      if (res.code == 200) {
        this.getPersonal();
        if (item.attention) {
          this.$message({
            message: "已取消关注",
            type: "success",
          });
        } else {
          this.$message({
            message: "关注成功",
            type: "success",
          });
        }
        item.attention = !item.attention;
        this.$parent.doctorListFn(item);
      } else {
        this.$message.error(res.message);
      }
      // this.islod = true;
    },
  },
  // },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.flex-div {
  display: flex;
  justify-content: space-between;

  .delete-find {
    padding-top: 5px;

    .iconfont {
      padding: 10px;

      &:hover {
        color: #333333;
      }

      color: #8f8f8f;
    }
  }
}

.widwd {
  /deep/ {
    span,
    p {
      background: transparent !important;
    }
  }
}

.avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 12px;
}

.group-one {
  display: flex;
  margin-top: 23px;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  cursor: pointer;
  margin-bottom: 30px;
  width: 890px;

  > .group-one-box2 {
    position: relative;
    margin-right: 20px;

    > .rectangular2 {
      position: absolute;
      width: 50px;
      height: 26px;
      opacity: 0.3;
      background: #000000;
      border-radius: 13px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      line-height: 26px;
      right: 10px;
      bottom: 10px;
    }

    > .banner2 {
      width: 270px;
      height: 200px;
      object-fit: cover;
      border-radius: 10px;
    }

    > .banner5 {
      width: 270px;
      height: 223px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  > .group-one-box {
    width: 310px;
    height: 182px;
    position: relative;
    margin-right: 20px;

    > .rectangular {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      opacity: 0.7;
      background: #000000;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -20px;
      font-size: 12px;
      color: #ffffff;

      > img {
        width: 16px;
        height: 18px;
        object-fit: cover;
      }
    }

    > .banner {
      width: 310px;
      height: 182px;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  > .group-one-right {
    cursor: pointer;
    flex: 1;
    border: none;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .subTitle2 {
      font-size: 18px;
      color: #8f8f8f;
      line-height: 1.8;
      margin-bottom: 10px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      /deep/ img {
        // max-width: 100%;
        max-width: 100%;
        max-height: 300px;
      }
    }

    > .group-one-top {
      display: flex;
      align-items: center;
      position: relative;

      .group-one-top-right {
        flex: 1;
        display: flex;
        flex-direction: column;

        > .one {
          display: flex;
          flex-direction: row;
          align-items: center;

          > .title {
            font-size: 24px;
            color: #333333;
          }

          > .position {
            font-size: 13px;
            color: $--themeColor;
            height: 25px;
            border: 1px solid $--themeColor;
            border-radius: 6px;
            text-align: center;
            line-height: 25px;
            margin-left: 12px;
            padding: 0 13px;
            box-sizing: border-box;
          }

          > .button {
            position: absolute;
            right: 0;
            width: 56px;
            height: 25px;
            background: $--themeColor;
            border-radius: 5px;
            color: #ffffff;
            text-align: center;
            line-height: 26px;
            margin-left: auto;
            font-size: 12px;
          }

          > .button2 {
            width: 66px;
            height: 25px;
            border: 1px solid #8f8f8f;
            border-radius: 5px;
            color: #8f8f8f;
            text-align: center;
            line-height: 26px;
            margin-left: auto;
            font-size: 12px;
          }
        }

        > .address {
          font-size: 16px;
          color: #8f8f8f;
          margin-top: 9px;
          line-height: 20px;
        }
      }

      > img {
        width: 70px;
        height: 70px;
        object-fit: cover;
        margin-right: 12px;
      }
    }

    > .line {
      min-height: 88px;
    }

    > .subTitle {
      font-size: 19px;
      color: #333333;
      margin-bottom: 10px;
      line-height: 44px;
      max-height: 150px;
      height: auto;
      box-sizing: border-box;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;

      /deep/ img {
        max-width: 100%;
        max-height: 300px;
      }
    }

    .data {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 20px;
      height: 30px;
      color: #8f8f8f;

      span {
        margin-right: 10px;
      }

      .data-bh {
        color: #e60012;
      }

      .data-icon {
        width: 24px;
        height: 24px;
      }
    }

    .creationTitle {
      font-size: 13px;
      font-weight: 400;
      color: $--themeColor;
      padding: 3px 12px;
      border: 1px solid $--themeColor;
      border-radius: 6px;
      margin-right: 10px;
      line-height: 20px;
    }
  }
}

.group-two {
  width: 890px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  color: #8f8f8f;
  padding-bottom: 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 30px;

  > .bottom {
    display: flex;
    margin-top: 10px;
    width: 890px;

    > .banner3 {
      width: 145px;
      height: 100px;
      margin-right: 20px;
      position: relative;

      img {
        width: 145px;
        height: 100px;
        object-fit: cover;
        border-radius: 10px;
      }

      .banner3-icon {
        position: absolute;
        width: 50px;
        height: 26px;
        opacity: 0.3;
        background: #000000;
        border-radius: 13px;
        font-size: 14px;
        color: #ffffff;
        text-align: center;
        line-height: 26px;
        bottom: 10px;
        right: 10px;
      }
    }

    > .group-two-div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
      flex-wrap: wrap;

      > .title {
        font-size: 24px;
        color: #333333;
        height: 43px;
        // margin-bottom: 10px;
      }

      > .subTitle {
        line-height: 1.8;
        overflow: hidden;
        display: block;
        width: 100%;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;

        /deep/ img {
          max-width: 100%;
          max-height: 300px;
        }
      }

      .group-two-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        color: #8f8f8f;
        margin-top: auto;
        height: 40px;

        .data-bh {
          color: #e60012;
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          margin-right: 10px;
        }

        span {
          margin-right: 16px;

          &:last-child {
            margin-right: 0;
          }
        }

        .creationTitle {
          font-size: 13px;
          font-weight: 400;
          color: $--themeColor;
          padding: 3px 12px;
          border: 1px solid $--themeColor;
          border-radius: 6px;
          margin-right: 10px;
          line-height: 20px;
        }

        .button {
          font-size: 14px;
          color: $--themeColor;
          width: 89px;
          height: 40px;
          background: #dbfbf8;
          border-radius: 5px;
          text-align: center;
          line-height: 40px;
          margin-left: auto;
          font-size: 12px;
        }
      }
    }
  }

  > .title {
    font-size: 24px;
    color: #333333;
    height: 43px;
    line-height: 43px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  > .subTitle {
    line-height: 1.8;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    /deep/ img {
      max-width: 100%;
      max-height: 300px;
    }
  }

  > .group-two-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    color: #8f8f8f;
    margin-top: 15px;

    > img {
      width: 30px;
      height: 30px;
      object-fit: cover;
      margin-right: 10px;
    }

    > span {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }

    > .button {
      font-size: 12px;
      color: $--themeColor;
      width: 89px;
      height: 40px;
      background: #dbfbf8;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      margin-left: auto;
    }
  }
}

.group-one-right2 {
  width: 890px;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 30px;
  cursor: pointer;

  > .subTitle2 {
    font-size: 18px;
    line-height: 1.8;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    /deep/ img {
      // max-width: 100%;
      max-width: 100%;
      max-height: 300px;
    }
  }

  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  color: #8f8f8f;
  font-size: 18px;

  > .group-one-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;

    > .group-one-top-right {
      flex: 1;
      display: flex;
      flex-direction: column;

      > .one {
        display: flex;
        flex-direction: row;
        align-items: center;

        > .title {
          font-size: 24px;
          color: #333333;
        }

        > .position {
          font-size: 13px;
          color: $--themeColor;
          padding: 0 13px;
          box-sizing: border-box;
          height: 25px;
          border: 1px solid $--themeColor;
          border-radius: 6px;
          text-align: center;
          line-height: 25px;
          margin-left: 12px;
        }

        > .button {
          position: absolute;
          right: 0;
          width: 56px;
          height: 25px;
          background: $--themeColor;
          border-radius: 5px;
          color: #ffffff;
          text-align: center;
          line-height: 26px;
          margin-left: auto;
          font-size: 12px;
        }

        > .button2 {
          width: 66px;
          height: 25px;
          border: 1px solid #8f8f8f;
          border-radius: 5px;
          color: #8f8f8f;
          text-align: center;
          line-height: 26px;
          margin-left: auto;
          font-size: 12px;
        }
      }

      > .address {
        font-size: 16px;
        color: #8f8f8f;
        margin-top: 9px;
      }
    }
  }

  > .subTitle {
    max-height: 82px;
    font-size: 24px;
    color: #333333;
    margin-bottom: 10px;
    line-height: 1.8;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;

    /deep/ img {
      max-width: 100%;
      max-height: 300px;
    }
  }

  .data {
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    height: 30px;
    color: #8f8f8f;

    span {
      margin-right: 16px;
    }

    .data-bh {
      color: #e60012;
    }

    .data-icon {
      width: 24px;
      height: 24px;
    }
  }

  .creationTitle {
    font-size: 13px;
    font-weight: 400;
    color: $--themeColor;
    padding: 3px 12px;
    border: 1px solid $--themeColor;
    border-radius: 6px;
    margin-right: 10px;
  }

  > .group {
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    margin-bottom: 10px;
  }

  > .group2 {
    margin-bottom: 20px;
  }
}

.topCheng {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

::v-deep {
  .text-container {
    width: 100% !important;
  }

  .el-dialog {
    display: flex;
    flex-direction: column;
    padding: 70px 80px;
    box-sizing: border-box;
    margin-top: 320px;

    > .el-dialog__body {
      font-size: 24px;
      color: #333333;
      text-align: center;
    }

    .popupsButton {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      cursor: pointer;

      > .popupsJudgment {
        &:last-child {
          background: $--themeColor;
          border: none;
          color: #ffffff;
        }

        width: 170px;
        height: 50px;
        background: #ffffff;
        border: 1px solid $--themeColor;
        border-radius: 26px;
        color: $--themeColor;
        font-size: 18px;
        text-align: center;
        line-height: 50px;
        margin-top: 60px;
      }
    }
  }

  .el-carousel__item .banner {
    width: 890px;
    height: 400px;
    object-fit: cover;
  }

  .el-carousel__arrow {
    display: none;
  }
}
</style>
