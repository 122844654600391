var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      (_vm.i + _vm.pagingI + 1) % _vm.newsListNum == 0 &&
      _vm.newsList[(_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1] &&
      !_vm.newsList[(_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1].isShow
    )?_c('div',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"advertise",on:{"click":function($event){$event.stopPropagation();_vm.jumpTo(
          _vm.newsList[(_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1].url,
          _vm.newsList[(_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1].id,
          _vm.newsList[(_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1].type
        )}}},[_c('div',{staticClass:"advertise_top"},[_c('div',{staticClass:"advertise_topDiv"},[_c('div',{staticClass:"advertise_top_text"},[_vm._v(" "+_vm._s(_vm.newsList[(_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1].title)+" ")]),_c('div',{staticClass:"rightchengDiv"},[_c('div',{staticClass:"rightchengDivSonDiv"},[_c('img',{staticClass:"advertiseImg",attrs:{"src":require("@image/gg.png")}}),_c('span',{staticClass:"rightchengDivSpan"},[_vm._v(_vm._s(_vm.newsList[(_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1].business)+" ")])]),_c('div',[_c('i',{staticClass:"el-icon-close closeImg",on:{"click":function($event){$event.stopPropagation();_vm.closeAd((_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1)}}})])])]),_c('div',{staticClass:"chengDiv"},[_c('img',{staticClass:"advertiseimage",attrs:{"src":_vm.newsList[(_vm.i + _vm.pagingI + 1) / _vm.newsListNum - 1].images}})])])])]):_vm._e(),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.dialogVisibleXin,"width":"460px"},on:{"update:visible":function($event){_vm.dialogVisibleXin=$event}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_c('span',[_vm._v("是否确认不再浏览此广告?")]),_c('span',{staticClass:"dialog-footer",staticStyle:{"display":"flex","flex-direction":"row","justify-content":"center","align-items":"center","padding-top":"30px"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){$event.stopPropagation();_vm.dialogVisibleXin = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.advertiseSure.apply(null, arguments)}}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }